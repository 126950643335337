<template>
    <div class="simple-list">
        <h6 v-if="label.length">
            {{ label }}
        </h6>
        <div class="item" v-for="(listItem, index) in list" :key="`item-${index}`">
            <button @click="removeItem(index)" class="btn btn-danger remove">
                {{ labels.remove }}
            </button>
            <div v-for="(input, inputName) in structure" class="simple-list-input" :key="`${inputName}-${inputName}`">
                <div v-if="input.kind == 'text'">
                    <FormulateInput
                        type="text"
                        :name="inputName"
                        error-behavior="blur"
                        :label="input.label"
                        :value="listItem[inputName] || ''"
                        @input="dataUpdated(inputName, index, $event)"
                    />
                </div>
                <div v-else-if="input.kind == 'textarea'">
                    <FormulateInput
                        type="textarea"
                        :name="inputName"
                        error-behavior="blur"
                        :label="input.label"
                        :value="listItem[inputName] || ''"
                        @input="dataUpdated(inputName, index, $event)"
                    />
                </div>
                <TextEditorCard
                    v-else-if="input.kind == 'richtext'"
                    :extra-configs="{
                        hideBgColorPicker: true,
                        hideColorPicker: true,
                    }"
                    :title="input.label"
                    :value="listItem[inputName] || ''"
                    @changed="dataUpdated(inputName, index, $event.value)"
                />
                <div v-else-if="input.kind == 'image'">
                    <FormulateInput
                        type="image"
                        :name="inputName"
                        error-behavior="blur"
                        :label="input.label"
                        :value="listItem[inputName] || ''"
                        @input="fileUpdated(inputName, index, $event)"
                    />
                    <img class="image-preview" v-if="listItem[inputName] && typeof listItem[inputName] == 'string'" :src="listItem[inputName]" />
                </div>
                <div v-else-if="input.kind == 'list'">
                    <SimpleList
                        :label="input.label"
                        :current-value="listItem[inputName] || []"
                        :structure="input.structure"
                        :add-new-item-label="input.addNewItemLabel || labels.add_new_item"
                        @list-updated="dataUpdated(inputName, index, $event)"
                    />
                </div>
                <div v-else-if="input.kind == 'checkbox'" class="checkbox">
                    <input @change="dataUpdated(inputName, index, $event)" type="checkbox" :name="inputName" :checked="listItem[inputName]" />
                    <label :for="inputName">
                        {{ input.label }}
                    </label>
                </div>
            </div>
        </div>
        <button class="btn btn-success" @click="addItem">
            {{ addNewItemLabel.length ? addNewItemLabel : labels.add_item }}
        </button>
    </div>
</template>

<script>
    import { debounce } from "@/services/debounce";
    import { mapGetters } from 'vuex';

    export default {
        name: 'SimpleList',
        data() {
            return {
                list: []
            }
        },
        components: {
            SimpleList: () => import("@/components/List/SimpleList"),
            TextEditorCard: () => import("@/components/Input/TextEditorCard")
        },
        props: {
            label: {
                type: String,
                default: ''
            },
            currentValue: {
                type: [Array, Object],
                default: () => {
                    return []
                }
            },
            structure: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            addNewItemLabel: {
                type: String,
                default: ''
            }
        },
        computed: {
            ...mapGetters('helper', ['labels']),
        },
        mounted() {
            // Current value can be passed as an object in format: { index: value } if so it will be automatically converted to array locally
            this.currentValToLocal();
        },
        methods: {
            currentValToLocal() {
                if (Array.isArray(this.currentValue)) {
                    this.list = this.currentValue;
                } else {
                    const totalVals  = Object.keys(this.currentValue).length;

                    if (totalVals) {
                        for (let i = 0; i < totalVals; i++) {
                            this.list.push(this.currentValue[i]);
                        }
                    }
                }
            },
            addItem() {
                let payload = {};

                for (const [fieldName, field] of Object.entries(this.structure)) {
                    payload[fieldName] = this.$defaultValue(field.kind);
                }

                this.list.push(payload);

                this.$emit('list-updated', this.list);
            },
            removeItem(index) {
                this.list.splice(index, 1);
                this.$emit('list-updated', this.list);
            },
            dataUpdated(name, index, value) {
                debounce(() => {
                    // Verify value type
                    switch(this.structure[name].kind) {
                        case 'text':
                            if (typeof value == 'string') {
                                this.list[index][name] = value;
                            }
                            break;
                        case 'checkbox':
                            this.list[index][name] = value.target.checked;
                            break;
                        default:
                            this.list[index][name] = value;
                    }

                    this.$emit('list-updated', this.list);
                }, 200, `space-input-${name}`);
            },
            fileUpdated(name, index, value) {
                debounce(() => {
                    if (value.files?.[0]?.file) {
                        this.list[index][name] = value.files[0].file;
                        this.$emit('list-updated', this.list);
                    }
                }, 200, `space-input-${name}`);
            }
        },
        watch: {
            currentValue() {
                this.currentValToLocal();
            }
        }
    };
</script>

<style lang="scss" scoped>
.simple-list {
    .item {
        border: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 25px;
        padding: 25px;
        position: relative;
        border-radius: .25rem;

        button.remove {
            position: absolute;
            right: 0;
            top: 0;
        }

        .image-preview {
            width: 100%;
            max-width: 320px;
            object-fit: contain;
            object-position: center;
            height: auto;
        }
    }

    &-input {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0px;
        }

        .checkbox {
            display: flex;
            align-items: center;

            input {
                width: 15px;
                height: 15px;
                cursor: pointer;
            }

            label {
                margin-bottom: 0;
                margin-left: 0.4rem;
                font-weight: 500;
            }
        }

        ::v-deep {
            .formulate-input-wrapper {
                .formulate-input-element {
                    max-width: unset;
                    width: 100%;
                }

                input,
                textarea,
                .editor {
                    width: 100%;
                }
            }
        }
    }
}
</style>